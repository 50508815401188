import React from "react"
import Container from "./container";
import "../styles/layout.scss";
import { useStaticQuery, Link, graphql } from "gatsby"

const NavigationItem = props => (
	<li><Link to={props.to}>{props.children}</Link></li> 
)

export default function Layout({ children }) {
	
	const data = useStaticQuery(
		graphql`
		  query {
			site {
			  siteMetadata {
				title
			  }
			}
		  }
		`
	)
	  
  return (
	  <div id="wrapper">
	  	<header>
		  <Container>
	  		<Link to={'/'}><h3>{data.site.siteMetadata.title}</h3> </Link>
			  
			  <nav>
			  	<ul>
	  				<NavigationItem to="/">Home</NavigationItem>
					<NavigationItem to="/about/">About</NavigationItem>
				</ul>
			  </nav>
		  </Container>
	  	</header>
	  	<main>
	  		{children}
	  	</main>
	  	<footer>
		  <Container>
	  		&copy; Niels van Renselaar 
		  </Container>
		</footer>
	</div>
  )
}